import { Controller } from "@hotwired/stimulus"
import confetti from 'canvas-confetti'

export default class extends Controller {
  static values = {
    popOnConnect: Boolean
  }

  connect () {
    if(this.popOnConnectValue) this.pop()
  }

  pop() {
    const { x, y } = this.element.getBoundingClientRect()

    confetti({
      particleCount: 300,
      startVelocity: 30,
      spread: 360,
      origin: {
        x: (x + this.element.offsetWidth / 2) / window.innerWidth,
        y: (y + this.element.offsetHeight / 2) / window.innerHeight
      }
    })
  }
}
